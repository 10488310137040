export const assets = {
  epahubb_logo: require("./images/epahubb-logo.png"),
  epahubb_logo_two: require("./images/epahubb-logo-2.png"),
  burger_icon: require("./images/burger-icon.png"),
  hero_image: require("./images/epahubb-hero-img.png"),
  dots_image: require("./images/epahubb-dots-image.png"),
  epahubb_ellipse: require("./images/epahubb-Ellipse-img.png"),
  star_shaped_image: require("./images/start-shaped-img.png"),
  lady_with_smilled_cheek: require("./images/epahubb-lady-smilling-up.png"),
  lady_with_a_man: require("./images/epahubb-developers.png"),
  man_with_glasses: require("./images/epahubb-man-with-glasses.png"),
  google_analytics_img: require("./images/google-analytics-img.png"),
  video_img: require("./images/epahubb-video-img.png"),
  checked_icon: require("./images/epahubb-checked-icon.png"),
  mobile_dev_img: require("./images/mobile-dev-img.png"),
  uiux_design_img: require("./images/epahubb-uiux-img.png"),
  web_dev_img: require("./images/epahubb-web-dev-img.png"),
  software_eng_img: require("./images/epahubb-software-eng-img.png"),
  steward_pharmacy_logo: require("./images/epahubb-steward-pharm-logo.png"),
  gilat_chemist_logo: require("./images/epahubb-gilest-chemist-logo.png"),
  trufunder_logo: require("./images/ephubb-trufunder-logo.png"),
  mar_luther_logo: require("./images/epahubb-mar-luther-logo.png"),
  education_image: require("./images/epahubb-education-img.png"),
  ecommerce_image: require("./images/epahubb-ecommerce-img.png"),
  forex_trading_image: require("./images/epahubb-forex-trading-img.png"),
  epahubb_dark_logo: require("./images/epahubb-dark-logo.png"),
  elvis: require("./images/elvis.png"),
  dummy: require("./images/dummy.jpg"),
  review_customer_three: require("./images/epahubb-review-customer-three.avif"),
  review_customer_four: require("./images/epahubb-review-customer-four.avif"),
  review_customer_five: require("./images/epahubb-review-customer-five.avif"),
  review_customer_six: require("./images/epahubb-review-customer-six.avif"),
  review_customer_seven: require("./images/epahubb-review-customer-seven.avif"),
  review_customer_eight: require("./images/epahubb-review-customer-eight.avif"),
  review_customer_nine: require("./images/epahubb-review-customer-nine.avif"),
  quote_icon: require("./images/quote-icon.png"),
  faqs_image: require("./images/epahubb-faqs-img.png"),
  faqs_arrow_up: require("./images/epahubb-faqs-arrow-up.png"),
  rand_logo: require("./images/rand-logo.png"),
  epahubb_project_image: require("./images/epahubb-project.png"),
  epahubb_edu_softwares: require("./images/eaphubb-education-softwares.png"),
  epahubb_ecommerce_softwares: require("./images/epahubb-e-commerce-softwares.png"),
  epahubb_forex_trading_softwares: require("./images/eaphubb-forex-trading-softwares.png"),
  epahubb_contact_us_image: require("./images/contactimage.png"),
  epahubb_facebook_icon: require("./images/facebook.png"),
  epahubb_twitter_icon: require("./images/mdi_twitter.png"),
  epahubb_instagram_icon: require("./images/ri_instagram-fill.png"),
  error_image: require("./images/epahubb-error-image.png"),
  project_success_image: require("./images/epahubb-project-success-image.png"),
}
